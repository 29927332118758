import React, { useState } from 'react'
import Dropdown from '../../../../common/Dropdown'
import { AutoCompleteDropdownContainer } from '../../SharedStyledComponents'
import { AutoCompleteDropdownProps } from '../../types'
import useDebounce from '../../../../../hooks/useDebounce'
import useTranslation from '../../../../../hooks/useTranslation'

// Disabled filter so the API can perform all the work
const filterNone = () => true

const AutoCompleteDropdown = (props: AutoCompleteDropdownProps) => {
  const {
    options,
    onSelectValue,
    value = null,
    placeholder,
    disableFiltering = false,
    loading = false,
    isDisabled = false,
    isClearable = true,
    isMulti,
    styles
  } = props
  const [queryString, setQueryString] = useState('')
  const debouncedQueryString = useDebounce(queryString, 400)
  const { t } = useTranslation()

  let testID: string | null = ''
  switch (placeholder) {
    case t('callsForSubmission:filter:status'):
      testID = 'byStatusFilter'
      break
    case t('callsForSubmission:table:marketSegments'):
      testID = 'marketSegmentsFilter'
      break
    case t('callsForSubmission:table:delegations'):
      testID = 'delegationsFilter'
      break
    case t('callsForSubmission:table:step'):
      testID = 'stepFilter'
      break
  }

  return (
    <AutoCompleteDropdownContainer>
      <Dropdown
        testID={testID}
        isFilter
        name={placeholder}
        options={options}
        value={value}
        isDisabled={isDisabled}
        onInputChange={value => setQueryString(value)}
        placeholder={placeholder}
        hasError={false}
        onBlur={() => {}}
        onSelect={onSelectValue}
        style={styles}
        isClearable={isClearable}
        isMulti={isMulti}
        filterOption={disableFiltering ? filterNone : undefined}
        noOptionsMessage={
          debouncedQueryString
            ? loading
              ? 'Loading...'
              : 'No options found'
            : `Search ${placeholder}...`
        }
        menuPortalTarget={document?.body}
      />
    </AutoCompleteDropdownContainer>
  )
}

export default AutoCompleteDropdown
