import gql from 'graphql-tag'
import useTranslation from '../../../hooks/useTranslation'
import useQuery from '../../../hooks/useQuery'
import { Form } from '../../../types'
import FormFieldsFragment from '../../../graphql/FormFieldsFragment'

export const formsQuery = gql`
  query forms($active: Boolean, $delegationIds: [String]) {
    forms(active: $active, delegationIds: $delegationIds) {
      ...FormFieldsFragment
    }
  }
  ${FormFieldsFragment}
`

interface FormObject {
  forms: Form[]
  loading: boolean
}

const useFormsQuery = (
  options = {},
  active = true,
  delegationIds?: string[],
  fetchPolicy = 'cache-first'
): FormObject => {
  const { t } = useTranslation()

  const { data, loading } = useQuery(formsQuery, {
    options,
    variables: { active, delegationIds },
    errorMessage: t('error:general:getForms'),
    fetchPolicy
  })

  return {
    forms: data?.forms,
    loading
  }
}

export default useFormsQuery
