import React, { useMemo, useState } from 'react'
import styled from 'styled-components/native'

import useTranslation from '../../../hooks/useTranslation'

import Modal from '../../../components/common/Modal'

import { ModalContainer, Title } from '../constants'
import { FormModalProps, ModalTabProps, ModalTabsEnum } from '../types'
import { View } from 'react-native'
import { ModalCloseIcon } from '../../../components/DynamicTable/components/common/ModalDialog/SharedStyledComponents'
import Form from './Form'

const TitleContainer = styled(View)`
  width: 100%;
  flex-direction: 'row'
    ${({ theme: { sizes } }) => `
        height: ${sizes[4] - 2}px;
  `};
`

const FormModal = (props: FormModalProps) => {
  const { isOpen, onClose, form } = props
  const [activeOption] = useState<ModalTabsEnum>(ModalTabsEnum.General)
  // Hooks
  const { t } = useTranslation()

  const modalTabs: ModalTabProps[] = [
    {
      tabKey: ModalTabsEnum.General,
      label: 'platformManagement:forms:menuItems:forms:general',
      component: props => <Form {...props} />
    }
  ]

  const CurrentComponent: any = useMemo(
    () => modalTabs.find(opt => opt.tabKey === activeOption)?.component,
    [form, activeOption]
  )

  const isEdit = !!form

  const componentProps = {
    onClose,
    form,
    isEdit
  }

  return (
    <Modal close={onClose} open={isOpen} styles={{ width: 700 }}>
      <TitleContainer>
        <Title>
          {t(
            `platformManagement:forms:menuItems:forms:${
              isEdit ? 'editForm' : 'addNew'
            }`
          )}
        </Title>

        <ModalCloseIcon onPress={onClose} />
      </TitleContainer>
      <ModalContainer style={{ marginTop: 10 }}>
        {CurrentComponent ? <CurrentComponent {...componentProps} /> : null}
      </ModalContainer>
    </Modal>
  )
}

export default FormModal
