import React, { useState, useMemo } from 'react'
import { View, ViewStyle } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
// Hooks
import useDebounce from '../../../hooks/useDebounce'
import useFeatureFlag from '../../../hooks/useFeatureFlag'
import useTranslation from '../../../hooks/useTranslation'
import useFormsQuery from '../../../screens/Product/hooks/useFormsQuery'
import useDelegationsQuery from '../../Product/hooks/useDelegationsQuery'
// Components
import Modal from '../components/Modal'
import TextInput from '../../../ui-library/TextInput'
import { TableContainer } from '../../../components/StaticForm'
import { CreateIcon } from '../../PlatformManagement/constants'
import { DynamicTable, useTable } from '../../../components/Table'
import { getFormsTableConfig } from '../components/Form/constants'
import { NEW_DYNAMIC_TABLE_FF } from '../../../components/DynamicTable/constants'
import { DynamicTable as NewDynamicTable } from '../../../components/DynamicTable'
import AutoCompleteDropdown from '../../../components/DynamicTable/components/common/AutoCompleteDropdown'
// Skeletons
import TableSkeleton from '../../../screens/HomeScreen/TableSkeleton'
import SkeletonContainer from '../../../components/skeletonLoadings/SkeletonContainer'
// Types
import { DropdownOption } from '../../../components/common/Dropdown'
import { FormValues } from '../../PlatformManagement/FormsManagement/types'

// Constants
const STATUS_OPTIONS: DropdownOption[] = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false }
]

// Styles
const FormTableContainer = styled(View)`
  width: 100%;
  height: 100%;
  ${({ theme: { colors } }) => `
    background: ${colors.appBackground};
  `}
`

const SearchRow = styled(View)`
  width: 100%;
  flex-direction: row;
  ${({ theme: { sizes } }) => `
    height: ${sizes[3] + sizes[4] + 2}px;
    marginTop: ${sizes[1] + sizes[2] - 2}px;
    paddingHorizontal: ${sizes[1] + sizes[2] - 2}px;
  `}
`
const CreateIconContainer = styled.View`
  margin-left: auto;
`

const Table = styled(View)`
  flex: 1;
  ${({ theme: { sizes } }) => `
    paddingHorizontal: ${sizes[1] + sizes[2] - 2}px;
  `}
`

const FormTab = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)
  const [currentForm, setCurrentForm] = useState<FormValues | undefined>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  // Filters
  const [statusFilter, setStatusFilter] = useState<any>(true)
  const [delegationId, setDelegationId] = useState<string | undefined>(
    undefined
  )

  const { forms, loading: fetchLoading } = useFormsQuery(
    {},
    statusFilter,
    delegationId ? [delegationId] : undefined,
    'network-only'
  )
  const { delegations, loading: loadingDelegations } = useDelegationsQuery()

  const [search, setSearch] = useState<string | undefined>(undefined)

  const debouncedSearch = useDebounce(search, 300)

  const delegationOptions: DropdownOption[] = useMemo(() => {
    if (!loadingDelegations && delegations?.length) {
      return delegations.map(delegation => ({
        label: delegation.name,
        value: delegation.id
      }))
    }
    return []
  }, [delegations, loadingDelegations])

  const filteredData = useMemo(() => {
    if (!fetchLoading && forms?.length) {
      if (debouncedSearch?.length) {
        return forms.filter(question => {
          const allTerms = [
            ...Object.keys(question),
            ...Object.values(question).map(
              value => String(JSON.stringify(value)) ?? String(value)
            )
          ]
          const includeTerm = allTerms.find(term =>
            term.toLowerCase().includes(debouncedSearch.toLowerCase())
          )
          return !!includeTerm
        })
      }
      return forms
    }
    return []
  }, [forms, fetchLoading, debouncedSearch])

  // Handlers
  const handleEditForm = (form: FormValues) => {
    setCurrentForm(form)
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const handleCreateForm = () => {
    setCurrentForm(undefined)
    setIsOpen(true)
  }

  // Table config
  const TableComponent = isInnovationNewDynamicTable
    ? NewDynamicTable
    : DynamicTable

  const tableProps: any = useTable({
    data: filteredData,
    config: getFormsTableConfig({
      handleEditForm
    })
  })

  // Render props
  const textInputStyle: ViewStyle = {
    minHeight: 0,
    alignSelf: 'center'
  }

  const textInputContainerStyle: ViewStyle = {
    width: theme.sizes[7],
    height: theme.sizes[3] + theme.sizes[4]
  }

  const autocompleteDropdownStyle: ViewStyle = {
    minHeight: 0,
    marginBottom: 0,
    maxWidth: '25vw',
    marginLeft: theme.sizes[3]
  }

  return (
    <FormTableContainer>
      <SearchRow>
        <TextInput
          value={search}
          style={textInputStyle}
          onChangeText={setSearch}
          containerStyles={textInputContainerStyle}
          placeholder={t('common:searchPlaceHolder')}
        />
        <AutoCompleteDropdown
          isMulti={false}
          isClearable={false}
          value={statusFilter}
          options={STATUS_OPTIONS}
          onSelectValue={(value: any) => setStatusFilter(!!value)}
          placeholder={t('formManagement:filters:status')}
          styles={autocompleteDropdownStyle}
        />
        <AutoCompleteDropdown
          isMulti={false}
          value={delegationId}
          options={delegationOptions}
          styles={autocompleteDropdownStyle}
          onSelectValue={value => setDelegationId(value)}
          placeholder={t('formManagement:filters:delegation')}
        />
        <CreateIconContainer>
          <CreateIcon
            disabled={false}
            isLoading={false}
            onPress={handleCreateForm}
            title={t('platformManagement:forms:menuItems:forms:addNew')}
          />
        </CreateIconContainer>
      </SearchRow>
      <Table>
        <SkeletonContainer isLoading={fetchLoading} Skeleton={TableSkeleton}>
          <TableContainer height="100%">
            <TableComponent
              {...tableProps}
              emptyMessage={t('formManagement:formTable:noForms')}
            />
          </TableContainer>
        </SkeletonContainer>
      </Table>
      <Modal isOpen={isOpen} onClose={onClose} form={currentForm} />
    </FormTableContainer>
  )
}

export default FormTab
